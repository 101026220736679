import React from "react";
import { Link } from "react-router-dom";

import {
  // Buttons
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  // Icons
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

function ShareSocialMedia() {
  const shareUrl = "https://tibb.pk";
  const title = "Tibb - App for Pakistani Doctors";

  return (
    <section className="relative">
      <div className="flex justify-evenly mt-5">
        <WhatsappShareButton
          url={shareUrl}
          title={title}
          separator=":: "
          className="Demo__some-network__share-button"
        >
          <WhatsappIcon size={50} round />
        </WhatsappShareButton>
        <FacebookShareButton
          url={shareUrl}
          quote={title}
          className="Demo__some-network__share-button"
        >
          <FacebookIcon size={50} round />
        </FacebookShareButton>

        <TwitterShareButton
          url={shareUrl}
          title={title}
          className="Demo__some-network__share-button"
        >
          <TwitterIcon size={50} round />
        </TwitterShareButton>

        <LinkedinShareButton
          url={shareUrl}
          className="Demo__some-network__share-button"
        >
          <LinkedinIcon size={50} round />
        </LinkedinShareButton>
      </div>
    </section>
  );
}

export default ShareSocialMedia;
