import React from "react";
import { Link } from "react-router-dom";

const svgPhone = [
  "M12.9665 7.00504C12.9637 5.34818 14.3047 4.00284 15.9615 4.00013L31.9337 3.97397C33.5906 3.97125 34.9359 5.3122 34.9386 6.96905L34.9943 40.9559C34.997 42.6128 33.6561 43.9581 31.9992 43.9608L16.027 43.987C14.3702 43.9897 13.0248 42.6488 13.0221 40.9919L12.9665 7.00504ZM14.9656 8.00168C14.9638 6.89711 15.8577 6.00021 16.9623 5.9984L30.9395 5.97551C32.0441 5.9737 32.941 6.86766 32.9428 7.97223L32.987 34.9621C32.9888 36.0666 32.0949 36.9635 30.9903 36.9653L17.0131 36.9882C15.9085 36.99 15.0116 36.0961 15.0098 34.9915L14.9656 8.00168ZM24.0099 41.9745C25.1131 41.9727 26.0059 41.0761 26.0041 39.9719C26.0023 38.8677 25.1065 37.974 24.0033 37.9758C22.9002 37.9776 22.0073 38.8742 22.0091 39.9784C22.0109 41.0826 22.9067 41.9763 24.0099 41.9745Z",
  "M23.1923 27.5397L23.1921 27.5395C21.6033 26.2237 20.297 25.1418 19.3858 24.1243C18.4764 23.1088 17.9766 22.1747 17.9758 21.1568C17.9745 19.5006 19.3937 18.2 21.2042 18.1985C22.2283 18.1977 23.2141 18.6337 23.8572 19.3235L23.9744 19.4492L24.0914 19.3233C24.7334 18.6325 25.7184 18.1948 26.7426 18.194C28.553 18.1925 29.9744 19.4907 29.9758 21.147C29.9766 22.1648 29.4785 23.0998 28.5707 24.1175C27.6642 25.1337 26.3654 26.2153 24.7861 27.5305L24.7702 27.5437L24.7699 27.544L23.982 28.1963L23.1923 27.5397Z",
];

function Call() {
  return (
    <a href="tel:03361118422">
      {/* border-4 border-green-600 */}
      <div className="flex fixed bottom-10 right-10 pr-4 rounded-full bg-blue-300 border-4 border-blue-400">
        <svg
          // animate-pulse
          // animate-bounce
          // animate-ping
          // animate-spin
          className="w-12 h-12 mx-auto animate-bounce rounded-full border-4 border-blue-400"
          viewBox="0 0 64 64"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            className="fill-current text-blue-300"
            width="64"
            height="64"
            rx="32"
          />
          {svgPhone.map(function (data, index) {
            return (
              <path
                key={"svgPhone-" + index}
                transform="translate(6, 5), scale(1.1)"
                className="stroke-current text-purple-100"
                d={data}
                strokeLinecap="square"
                strokeWidth="0"
                fill="blue"
                fillRule="evenodd"
              />
            );
          })}
        </svg>

        <div className="my-auto pl-1">
          <span>Request Demo </span>
          <br />
          <span>0336-111-TIBB</span>
        </div>
      </div>
    </a>
  );
}

export default Call;
