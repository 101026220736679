import React, { useEffect } from "react";

function Terms() {
  // Set light or dark mode
  const darkMode = false;
  useEffect(() => {
    localStorage.setItem("dark-mode", darkMode);
    if (darkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, []);

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Page content */}
      <main className="flex-grow">
        <section className="relative">
          {/* End background gradient (light version only) */}
          <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
            <h1
              className="h1 font-red-hat-display mb-4 pt-10 pb-5 text-center"
              data-aos="fade-down"
            >
              Terms and Conditions
            </h1>
            <p
              className="text-xl text-gray-600 dark:text-gray-400 pb-10"
              data-aos="fade-down"
              data-aos-delay="150"
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Mi in
              nulla posuere sollicitudin aliquam. Amet nulla facilisi morbi
              tempus iaculis. Massa ultricies mi quis hendrerit dolor magna
              eget. Id semper risus in hendrerit gravida rutrum quisque. Ac
              tortor vitae purus faucibus ornare suspendisse sed nisi lacus.
              Luctus venenatis lectus magna fringilla urna. Pretium nibh ipsum
              consequat nisl vel pretium lectus quam id. Maecenas ultricies mi
              eget mauris pharetra et ultrices neque ornare. Dictum non
              consectetur a erat nam at lectus urna. Enim nulla aliquet
              porttitor lacus luctus accumsan tortor posuere. Nisi quis eleifend
              quam adipiscing vitae proin sagittis nisl.
            </p>
            <p
              className="text-xl text-gray-600 dark:text-gray-400 pb-10"
              data-aos="fade-down"
              data-aos-delay="150"
            >
              Vitae purus faucibus ornare suspendisse sed nisi. Varius morbi
              enim nunc faucibus. Vitae suscipit tellus mauris a diam maecenas
              sed enim. Nunc lobortis mattis aliquam faucibus purus. Enim nulla
              aliquet porttitor lacus. Nascetur ridiculus mus mauris vitae
              ultricies. Velit ut tortor pretium viverra suspendisse potenti
              nullam. Pulvinar pellentesque habitant morbi tristique senectus. A
              pellentesque sit amet porttitor eget dolor morbi. Aliquet lectus
              proin nibh nisl condimentum id. At elementum eu facilisis sed
              odio. Enim sit amet venenatis urna cursus eget nunc. Pretium
              vulputate sapien nec sagittis aliquam malesuada bibendum arcu
              vitae. Vulputate odio ut enim blandit volutpat maecenas volutpat
              blandit aliquam. Ipsum dolor sit amet consectetur. Augue eget arcu
              dictum varius. In aliquam sem fringilla ut morbi tincidunt augue.
            </p>
            <p
              className="text-xl text-gray-600 dark:text-gray-400 pb-10"
              data-aos="fade-down"
              data-aos-delay="150"
            >
              Lacus vel facilisis volutpat est velit egestas. Eu consequat ac
              felis donec. Vitae turpis massa sed elementum tempus egestas sed.
              Sollicitudin tempor id eu nisl nunc mi ipsum. At tempor commodo
              ullamcorper a lacus vestibulum sed. In pellentesque massa placerat
              duis ultricies. Lectus sit amet est placerat in egestas. Gravida
              dictum fusce ut placerat orci nulla pellentesque dignissim. Mauris
              vitae ultricies leo integer malesuada nunc. Tellus cras adipiscing
              enim eu turpis. Justo eget magna fermentum iaculis eu non diam
              phasellus vestibulum. Condimentum id venenatis a condimentum
              vitae.
            </p>
            <p
              className="text-xl text-gray-600 dark:text-gray-400 pb-10"
              data-aos="fade-down"
              data-aos-delay="150"
            >
              Aliquam faucibus purus in massa tempor nec feugiat nisl pretium.
              Phasellus vestibulum lorem sed risus ultricies tristique nulla
              aliquet enim. Pharetra magna ac placerat vestibulum lectus.
              Pellentesque id nibh tortor id aliquet lectus proin nibh. Sagittis
              aliquam malesuada bibendum arcu vitae elementum curabitur. Etiam
              dignissim diam quis enim. Sapien faucibus et molestie ac feugiat
              sed lectus vestibulum. Sed cras ornare arcu dui vivamus. Morbi
              enim nunc faucibus a. Blandit volutpat maecenas volutpat blandit.
              Tincidunt arcu non sodales neque sodales. Congue eu consequat ac
              felis donec et.
            </p>
            <p
              className="text-xl text-gray-600 dark:text-gray-400 pb-10"
              data-aos="fade-down"
              data-aos-delay="150"
            >
              Nam libero justo laoreet sit amet cursus. Non arcu risus quis
              varius quam quisque id diam vel. Mi eget mauris pharetra et
              ultrices neque. Sit amet nisl suscipit adipiscing bibendum est.
              Enim eu turpis egestas pretium aenean. Cursus vitae congue mauris
              rhoncus. Sit amet volutpat consequat mauris nunc congue nisi vitae
              suscipit. Risus sed vulputate odio ut enim blandit volutpat. Amet
              consectetur adipiscing elit ut. Eu feugiat pretium nibh ipsum
              consequat nisl. Etiam dignissim diam quis enim lobortis
              scelerisque fermentum dui faucibus.
            </p>
            <p
              className="text-xl text-gray-600 dark:text-gray-400 pb-10"
              data-aos="fade-down"
              data-aos-delay="150"
            >
              Ultrices gravida dictum fusce ut placerat orci nulla pellentesque.
              Maecenas pharetra convallis posuere morbi leo. Tempus urna et
              pharetra pharetra massa massa ultricies mi quis. Ac ut consequat
              semper viverra. Massa tempor nec feugiat nisl pretium fusce.
              Sollicitudin nibh sit amet commodo nulla. Cursus turpis massa
              tincidunt dui ut ornare lectus sit amet. Interdum velit laoreet id
              donec ultrices tincidunt arcu non. Pharetra sit amet aliquam id
              diam maecenas ultricies mi. Risus sed vulputate odio ut enim
              blandit volutpat maecenas volutpat. Scelerisque eleifend donec
              pretium vulputate. Scelerisque in dictum non consectetur a erat
              nam. Et pharetra pharetra massa massa ultricies mi quis. Nunc
              congue nisi vitae suscipit. Auctor neque vitae tempus quam
              pellentesque nec nam. Est ultricies integer quis auctor elit sed
              vulputate. Lacinia quis vel eros donec ac odio tempor orci
              dapibus. Purus faucibus ornare suspendisse sed nisi lacus sed
              viverra. Elit duis tristique sollicitudin nibh sit amet. Tellus
              rutrum tellus pellentesque eu. Ut sem nulla pharetra diam sit amet
              nisl suscipit adipiscing. Tristique senectus et netus et
              malesuada.
            </p>
            <p
              className="text-xl text-gray-600 dark:text-gray-400 pb-10"
              data-aos="fade-down"
              data-aos-delay="150"
            >
              Suspendisse interdum consectetur libero id faucibus. Cras pulvinar
              mattis nunc sed. In metus vulputate eu scelerisque felis imperdiet
              proin. Dictumst vestibulum rhoncus est pellentesque elit
              ullamcorper dignissim cras. Velit sed ullamcorper morbi tincidunt.
              Congue eu consequat ac felis donec. Nisl vel pretium lectus quam
              id leo in vitae turpis. Viverra maecenas accumsan lacus vel
              facilisis volutpat. Et netus et malesuada fames ac turpis egestas.
              Eu scelerisque felis imperdiet proin fermentum leo vel. Vel eros
              donec ac odio tempor orci dapibus. Sagittis purus sit amet
              volutpat consequat. Laoreet sit amet cursus sit amet. Fusce ut
              placerat orci nulla pellentesque dignissim. Adipiscing bibendum
              est ultricies integer quis auctor. Habitasse platea dictumst
              vestibulum rhoncus. In fermentum et sollicitudin ac orci. Ipsum
              dolor sit amet consectetur adipiscing elit duis tristique
              sollicitudin. Placerat vestibulum lectus mauris ultrices eros in
              cursus turpis massa. In metus vulputate eu scelerisque felis
              imperdiet proin. Volutpat ac tincidunt vitae semper quis lectus
              nulla. A condimentum vitae sapien pellentesque habitant. Odio
              pellentesque diam volutpat commodo sed. Neque egestas congue
              quisque egestas diam in arcu cursus euismod. Eget velit aliquet
              sagittis id. In nibh mauris cursus mattis molestie a iaculis.
              Dignissim enim sit amet venenatis urna cursus eget nunc. In massa
              tempor nec feugiat. Lacus laoreet non curabitur gravida. Malesuada
              pellentesque elit eget gravida. Adipiscing diam donec adipiscing
              tristique risus nec feugiat in. Diam ut venenatis tellus in metus.
              Nisl rhoncus mattis rhoncus urna neque viverra justo nec. At quis
              risus sed vulputate odio ut enim blandit volutpat. Ornare massa
              eget egestas purus viverra. Leo a diam sollicitudin tempor. Urna
              nec tincidunt praesent semper feugiat nibh sed pulvinar proin.
              Viverra accumsan in nisl nisi. Tincidunt ornare massa eget egestas
              purus viverra. Posuere ac ut consequat semper viverra nam libero
              justo laoreet. Eget nunc scelerisque viverra mauris in aliquam sem
              fringilla ut.
            </p>
            <p
              className="text-xl text-gray-600 dark:text-gray-400 pb-10"
              data-aos="fade-down"
              data-aos-delay="150"
            >
              Elementum curabitur vitae nunc sed velit. Aenean vel elit
              scelerisque mauris pellentesque. Massa eget egestas purus viverra.
              Habitant morbi tristique senectus et netus et malesuada. Ornare
              arcu odio ut sem nulla pharetra. Eget arcu dictum varius duis at
              consectetur lorem donec massa. Enim ut tellus elementum sagittis.
              Venenatis cras sed felis eget velit. Diam quis enim lobortis
              scelerisque fermentum. Luctus accumsan tortor posuere ac ut
              consequat. Nulla pharetra diam sit amet. Elit at imperdiet dui
              accumsan sit amet nulla. Ipsum consequat nisl vel pretium.
              Dignissim diam quis enim lobortis scelerisque fermentum dui. Velit
              sed ullamcorper morbi tincidunt ornare massa eget egestas purus.
              Laoreet non curabitur gravida arcu ac tortor dignissim convallis
              aenean. Orci ac auctor augue mauris augue. Faucibus purus in massa
              tempor nec feugiat. In dictum non consectetur a erat nam at lectus
              urna. Dignissim suspendisse in est ante in nibh mauris cursus
              mattis. Augue interdum velit euismod in pellentesque massa
              placerat duis. Non diam phasellus vestibulum lorem sed risus
              ultricies tristique nulla. Sapien et ligula ullamcorper malesuada.
              Malesuada fames ac turpis egestas integer eget. Aliquet nibh
              praesent tristique magna sit amet purus gravida.
            </p>
            <p
              className="text-xl text-gray-600 dark:text-gray-400 pb-10"
              data-aos="fade-down"
              data-aos-delay="150"
            >
              Aliquet eget sit amet tellus cras adipiscing enim. At quis risus
              sed vulputate odio. Duis at tellus at urna condimentum mattis
              pellentesque id. Porttitor leo a diam sollicitudin tempor id eu
              nisl. Enim diam vulputate ut pharetra. Ornare suspendisse sed nisi
              lacus sed. Nisl rhoncus mattis rhoncus urna neque viverra justo
              nec ultrices. Turpis massa sed elementum tempus egestas sed. Diam
              volutpat commodo sed egestas egestas fringilla. Duis ut diam quam
              nulla porttitor massa id neque aliquam. Tellus cras adipiscing
              enim eu turpis egestas pretium aenean. Diam in arcu cursus
              euismod. Eros donec ac odio tempor orci.
            </p>
          </div>
        </section>
      </main>
    </div>
  );
}

export default Terms;
