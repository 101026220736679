import React from "react";

function Team() {
  return (
    <section className="bg-gradient-to-b from-gray-100 to-white border-t border-gray-200">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2">Our Team</h2>
          </div>

          {/* Team members */}
          <div
            className="max-w-sm sm:max-w-5xl mx-auto sm:flex sm:flex-wrap sm:justify-center -my-6 sm:-my-8 sm:-mx-3"
            data-aos-id-team
          >
            {/* 1st member */}
            <div
              className="sm:w-1/2 md:w-1/3 py-6 sm:py-8 sm:px-3"
              data-aos="zoom-y-out"
              data-aos-anchor="[data-aos-id-team]"
            >
              <div className="flex flex-col items-center">
                <img
                  className="rounded-full mb-4"
                  src={require("../images/team-member-01.jpeg").default}
                  width="120"
                  height="120"
                  alt="Team member 01"
                />
                <h4 className="text-xl font-bold mb-1">Azeem Ghumman</h4>
                <div className="text-blue-600 font-medium mb-2">
                  CEO & Co-Founder
                </div>
                <p className="text-gray-600 text-center mb-3">
                  Something about Azeem!
                </p>
                {/* Social links */}
                {/* <ul className="flex">
                  <li>
                    <a
                      className="flex justify-center items-center text-white bg-teal-500 dark:text-teal-500 dark:bg-gray-800 hover:underline hover:bg-teal-600 rounded-full transition duration-150 ease-in-out"
                      href="https://www.linkedin.com/in/azeemghumman/"
                      aria-label="Linkedin"
                      target="_blank"
                    >
                      <svg
                        className="w-8 h-8 fill-current"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M23.3 8H8.7c-.4 0-.7.3-.7.7v14.7c0 .3.3.6.7.6h14.7c.4 0 .7-.3.7-.7V8.7c-.1-.4-.4-.7-.8-.7zM12.7 21.6h-2.3V14h2.4v7.6h-.1zM11.6 13c-.8 0-1.4-.7-1.4-1.4 0-.8.6-1.4 1.4-1.4.8 0 1.4.6 1.4 1.4-.1.7-.7 1.4-1.4 1.4zm10 8.6h-2.4v-3.7c0-.9 0-2-1.2-2s-1.4 1-1.4 2v3.8h-2.4V14h2.3v1c.3-.6 1.1-1.2 2.2-1.2 2.4 0 2.8 1.6 2.8 3.6v4.2h.1z" />
                      </svg>
                    </a>
                  </li>
                </ul> */}
              </div>
            </div>

            {/* 2nd member */}
            <div
              className="sm:w-1/2 md:w-1/3 py-6 sm:py-8 sm:px-3"
              data-aos="zoom-y-out"
              data-aos-anchor="[data-aos-id-team]"
            >
              <div className="flex flex-col items-center">
                <img
                  className="rounded-full mb-4"
                  src={require("../images/team-member-02.jpeg").default}
                  width="120"
                  height="120"
                  alt="Team member 2"
                />
                <h4 className="text-xl font-bold mb-1">
                  Dr. Khuda Bukhsh Saleemi
                </h4>
                <div className="text-blue-600 font-medium mb-2">Co-Founder</div>
                <p className="text-gray-600 text-center mb-3">
                  Something about KB!
                </p>
              </div>
            </div>

            {/* 3rd member */}
            <div
              className="sm:w-1/2 md:w-1/3 py-6 sm:py-8 sm:px-3"
              data-aos="zoom-y-out"
              data-aos-anchor="[data-aos-id-team]"
            >
              <div className="flex flex-col items-center">
                <img
                  className="rounded-full mb-4"
                  src={require("../images/team-member-03.jpeg").default}
                  width="120"
                  height="120"
                  alt="Team member 3"
                />
                <h4 className="text-xl font-bold mb-1">Junaid Shaukat</h4>
                <div className="text-blue-600 font-medium mb-2">Co-Founder</div>
                <p className="text-gray-600 text-center mb-3">
                  Something about Junaid!
                </p>
              </div>
            </div>

            {/* 4th member */}
            <div
              className="sm:w-1/2 md:w-1/3 py-6 sm:py-8 sm:px-3"
              data-aos="zoom-y-out"
              data-aos-anchor="[data-aos-id-team]"
            >
              <div className="flex flex-col items-center">
                <img
                  className="rounded-full mb-4"
                  src={require("../images/team-member-04.jpeg").default}
                  width="120"
                  height="120"
                  alt="Team member 4"
                />
                <h4 className="text-xl font-bold mb-1">Wikram Das</h4>
                <div className="text-blue-600 font-medium mb-2">
                  Software Engineer
                </div>
                <p className="text-gray-600 text-center mb-3">
                  Something about Wikram!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Team;
