import React, { useState } from "react";
import axios from "axios";

import ShareSocialMedia from "../partials/ShareSocialMedia";

function HeroHome() {
  const onSubmit = (evt) => {
    evt.preventDefault();

    if (name.replace(/ /g, "").length == 0) {
      setError("Please enter your first name");
      return;
    }
    if (phone.replace(/ /g, "").length == 0) {
      setError("Please enter your phone");
      return;
    }

    axios
      .post(
        `https://rxplqw36gc.execute-api.us-east-1.amazonaws.com/prod/request-demo`,
        {
          firstName: name,
          phone: phone,
        }
      )
      .then((res) => {
        console.log(res.data);
        setServerResponseText(
          "Request submitted! Someone in our team will contact you shortly."
        );
        setServerResponseColor("text-green-800");
      })
      .catch(function (error) {
        setServerResponseText(
          "Error connecting to server. Please call us instead!"
        );
        setServerResponseColor("text-red-800");
      });

    setPromptSubmitted(true);
  };

  const [isPromptSubmitted, setPromptSubmitted] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");
  const [serverResponseText, setServerResponseText] = useState("");
  const [serverResponseColor, setServerResponseColor] = useState(
    "text-green-800"
  );

  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-10 pb-10 md:pt-20 md:pb-16">
          {/* Hero content */}
          <div className="md:grid md:grid-cols-12 md:gap-12 lg:gap-20 items-center">
            {/* Content */}
            <div className="md:col-span-6 lg:col-span-6 mb-8 md:mb-0 text-center md:text-left">
              <h1
                className="h1 lg:text-6xl mb-4 font-red-hat-display font-extrabold"
                data-aos="fade-down"
              >
                Healthcare, Reimagined
              </h1>
              <p
                className="text-xl text-gray-600 dark:text-gray-400"
                data-aos="fade-down"
                data-aos-delay="150"
              >
                Manage your entire medical practice from your phone!
              </p>
              {/* Download Apps */}
              <div className="flex border-2 space-x-2 mt-5">
                <a
                  className=""
                  href="https://play.google.com/store?hl=en_US&gl=US"
                  target="_blank"
                >
                  <img
                    className=""
                    src={require("../images/google.png").default}
                    alt="Play Store"
                  />
                </a>
                <a
                  className=""
                  href="https://www.apple.com/app-store/"
                  target="_blank"
                >
                  <img
                    className=""
                    src={require("../images/apple.png").default}
                    alt="App Store"
                  />
                </a>
              </div>

              {/* CTA form */}
              <form
                onSubmit={onSubmit}
                className="my-8"
                data-aos="fade-down"
                data-aos-delay="300"
              >
                <div className="font-architects-daughter text-xl text-blue-600 mb-2">
                  Request Demo
                </div>
                {!isPromptSubmitted && (
                  <div>
                    <div className="flex flex-col sm:flex-row justify-center max-w-sm mx-auto sm:max-w-md md:mx-0">
                      <input
                        type="text"
                        className="form-input w-full mb-2 sm:mb-0 sm:mr-2"
                        placeholder="First Name"
                        aria-label="First Name"
                        value={name}
                        onChange={(e) => {
                          setError("");
                          setName(e.target.value);
                        }}
                      />

                      <input
                        type="tel"
                        className="form-input w-full mb-2 sm:mb-0 sm:mr-2"
                        placeholder="Phone number"
                        aria-label="Phone number"
                        value={phone}
                        onChange={(e) => {
                          setError("");
                          setPhone(e.target.value);
                        }}
                      />
                      <input
                        className="btn text-white bg-blue-500 hover:bg-blue-400 flex-shrink-0 cursor-pointer"
                        type="submit"
                      />
                    </div>
                    {error && <span className="text-red-900">{error}</span>}
                  </div>
                )}
                {isPromptSubmitted && (
                  <div className="">
                    <p
                      className={`text-center lg:text-left ${serverResponseColor} lg:absolute opacity-75 text-sm`}
                    >
                      {serverResponseText}
                    </p>
                  </div>
                )}
              </form>

              <div className="font-architects-daughter text-xl text-blue-600 my-5">
                Share with other doctors
              </div>
              <ShareSocialMedia />
            </div>

            {/* Mobile mockup */}
            <div
              className="md:col-span-6 lg:col-span-6 text-center md:text-right"
              data-aos="fade-up"
              data-aos-delay="450"
            >
              <div className="inline-flex relative justify-center items-center">
                {/* Glow illustration */}
                <svg
                  className="absolute mr-12 mt-32 pointer-events-none -z-1 dark:opacity-40"
                  aria-hidden="true"
                  width="678"
                  height="634"
                  viewBox="0 0 678 634"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="240"
                    cy="394"
                    r="240"
                    fill="url(#piphoneill_paint0_radial)"
                    fillOpacity=".4"
                  />
                  <circle
                    cx="438"
                    cy="240"
                    r="240"
                    fill="url(#piphoneill_paint1_radial)"
                    fillOpacity=".6"
                  />
                  <defs>
                    <radialGradient
                      id="piphoneill_paint0_radial"
                      cx="0"
                      cy="0"
                      r="1"
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="rotate(90 -77 317) scale(189.054)"
                    >
                      {/* <stop stopColor="#667EEA" />
                      <stop offset="1" stopColor="#667EEA" stopOpacity=".01" /> */}
                      <stop stopColor="#0000FF" />
                      <stop offset="1" stopColor="#0000FF" stopOpacity=".01" />
                    </radialGradient>
                    <radialGradient
                      id="piphoneill_paint1_radial"
                      cx="0"
                      cy="0"
                      r="1"
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="rotate(90 99 339) scale(189.054)"
                    >
                      {/* <stop stopColor="#9F7AEA" />
                      <stop offset="1" stopColor="#9F7AEA" stopOpacity=".01" /> */}
                      <stop stopColor="#9F7AEA" />
                      <stop offset="1" stopColor="#9F7AEA" stopOpacity=".01" />
                    </radialGradient>
                  </defs>
                </svg>
                {/* iPhone mockup */}
                <img
                  className="relative max-w-full mx-auto md:mr-0 md:max-w-none h-auto pointer-events-none"
                  src={require("../images/mobile-with-logo.png").default}
                  width="344"
                  height="674"
                  alt="iPhone mockup"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroHome;
