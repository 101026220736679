import React, { useState } from "react";

function PricingTables() {
  const [priceOutput] = useState({
    plan1: ["", "FREE", ""],
    plan2: ["PKR", "1,750", "/month"],
    plan3: ["PKR", "4,200", "/month"],
  });

  const [plans] = useState([
    {
      heading: "Starter",
      short_description: "Check out our feature set before you pay!",
      delay: "400",
      bgcolor: "bg-blue-100",
      currency: "",
      price: "FREE",
      price_frequency: "",
      features: [
        "30 patient records / month",
        "Send umlimited SMS to patients",
        "Unlimited Storage",
        "Use on multiple devices",
        "Secure your data with cloud backup",
        "Access to dashboard",
      ],
    },
    {
      heading: "Basic",
      short_description:
        "Create digital records and manage them from your phone!",
      delay: "500",
      bgcolor: "bg-blue-200",
      currency: "PKR",
      price: "1,750",
      price_frequency: "/month",
      features: [
        "Add unlimited patient records",
        "Send umlimited SMS to patients",
        "Unlimited Storage",
        "Use on multiple devices",
        "Secure your data with cloud backup",
        "Access to dashboard",
      ],
    },
    {
      heading: "Premium",
      short_description:
        "Super-charge your practice with a team of scribes and schedulers!",
      delay: "600",
      bgcolor: "bg-blue-300",
      currency: "PKR",
      price: "4,200",
      price_frequency: "/month",
      features: [
        "Add unlimited patient records",
        "Send umlimited SMS to patients",
        "Unlimited Storage",
        "Use on multiple devices",
        "Secure your data with cloud backup",
        "Access to dashboard",
        "Support for Instant Visits",
        "Scribes trascribe your snapshots",
        "Schedulers help with appointments and patient retention",
      ],
    },
  ]);

  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12">
            <h2 className="h2 mb-4" data-aos="fade-up">
              Pricing is Simple
            </h2>
            <p
              className="text-xl text-black"
              data-aos="fade-up"
              // data-aos-delay="200"
            >
              Use the app for free until you are confident you need it!
            </p>
          </div>

          {/* Pricing tables */}
          <div>
            {/* <div className="relative flex flex-col h-full py-5 px-6 rounded bg-white shadow-xl" data-aos="zoom-y-out" data-aos-delay="450"> */}
            <div className="max-w-sm mx-auto grid gap-8 lg:grid-cols-3 lg:gap-6 items-start lg:max-w-none">
              {plans.map(function (plan, plan_idx) {
                return (
                  <div
                    className={
                      "relative flex flex-col h-full p-6 rounded shadow-xl " +
                      plan.bgcolor
                    }
                    // data-aos="fade-up"
                    // data-aos-delay={plan.delay}
                  >
                    <div className="mb-4 pb-4 border-b border-gray-700">
                      <div className="h4 text-blue-600 mb-1">
                        {plan.heading}
                      </div>
                      <div className="inline-flex items-baseline mb-2">
                        <span className="text-1xl md:text-2xl pr-2 font-medium text-black">
                          {plan.currency}
                        </span>
                        <span className="h3">{plan.price}</span>
                        <span className="font-medium text-black">
                          {plan.price_frequency}
                        </span>
                      </div>
                      <div className="text-black">{plan.short_description}</div>
                    </div>
                    <div className="font-medium mb-3">Features include:</div>
                    <ul className="text-black -mb-3 flex-grow">
                      {plan.features.map(function (feature, feature_idx) {
                        return (
                          <li className="flex items-center mb-3">
                            <svg
                              className="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0"
                              viewBox="0 0 12 12"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                            </svg>
                            <span>{feature}</span>
                          </li>
                        );
                      })}
                    </ul>
                    <div className="p-3 mt-6">
                      <a
                        className="btn-sm text-white bg-blue-500 hover:bg-blue-400 w-full"
                        href="tel:03361118422"
                      >
                        Call 0336-1118422
                      </a>
                    </div>
                  </div>
                );
              })}

              {/* Pricing table 2 */}
              {/* <div
                className="relative flex flex-col h-full p-6 rounded bg-yellow-300 shadow-xl"
                data-aos="fade-up"
                // data-aos-delay="400"
              >
                <div className="mb-4 pb-4 border-b border-gray-700">
                  <div className="h4 text-purple-600 mb-1">Basic</div>
                  <div className="inline-flex items-baseline mb-2">
                    <span className="text-1xl md:text-2xl pr-2 font-medium text-black">
                      {priceOutput.plan2[0]}
                    </span>
                    <span className="h3">{priceOutput.plan2[1]}</span>
                    <span className="font-medium text-black">
                      {priceOutput.plan2[2]}
                    </span>
                  </div>
                  <div className="text-black">
                    Create digital records and manage them from your phone.
                  </div>
                </div>
                <div className="font-medium mb-3">Features include:</div>
                <ul className="text-black -mb-3 flex-grow">
                  <li className="flex items-center mb-3">
                    <svg
                      className="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>First Feature</span>
                  </li>
                  <li className="flex items-center mb-3">
                    <svg
                      className="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Second Feature</span>
                  </li>
                  <li className="flex items-center mb-3">
                    <svg
                      className="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Third Feature</span>
                  </li>
                  <li className="flex items-center mb-3">
                    <svg
                      className="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Fourth Feature</span>
                  </li>
                  <li className="flex items-center mb-3">
                    <svg
                      className="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Fifth Feature</span>
                  </li>
                  <li className="flex items-center mb-3">
                    <svg
                      className="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Sixth Feature</span>
                  </li>
                </ul>
                <div className="p-3 mt-6">
                  <a
                    className="btn-sm text-white bg-teal-500 hover:bg-teal-400 w-full"
                    href="tel:03361118422"
                  >
                    Call 0336-1118422
                  </a>
                </div>
              </div> */}

              {/* Pricing table 3 */}
              {/* <div
                className="relative flex flex-col h-full p-6 rounded bg-lightgreen-400 shadow-xl"
                data-aos="fade-up"
                // data-aos-delay="500"
              >
                <div className="mb-4 pb-4 border-b border-gray-700">
                  <div className="h4 text-purple-600 mb-1">Premium</div>
                  <div className="inline-flex items-baseline mb-2">
                    <span className="text-1xl md:text-2xl pr-2 font-medium text-black">
                      {priceOutput.plan3[0]}
                    </span>
                    <span className="h3">{priceOutput.plan3[1]}</span>
                    <span className="font-medium text-black">
                      {priceOutput.plan3[2]}
                    </span>
                  </div>
                  <div className="text-black">
                    Super-charge your practice with a team of scribes and
                    schedulers
                  </div>
                </div>
                <div className="font-medium mb-3">Features include:</div>
                <ul className="text-black -mb-3 flex-grow">
                  <li className="flex items-center mb-3">
                    <svg
                      className="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>First Feature</span>
                  </li>
                  <li className="flex items-center mb-3">
                    <svg
                      className="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Second Feature</span>
                  </li>
                  <li className="flex items-center mb-3">
                    <svg
                      className="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Third Feature</span>
                  </li>
                  <li className="flex items-center mb-3">
                    <svg
                      className="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Fourth Feature</span>
                  </li>
                </ul>
                <div className="p-3 mt-6">
                  <a
                    className="btn-sm text-white bg-teal-500 hover:bg-teal-400 w-full"
                    href="tel:03361118422"
                  >
                    Call 0336-1118422
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PricingTables;
