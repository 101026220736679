import React from "react";
import Accordion from "../utils/Accordion";

import ShareSocialMedia from "../partials/ShareSocialMedia";

function Faqs() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-200">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-20">
            <h2 className="h2">Frequently Answered Questions</h2>
          </div>

          {/* Faqs */}
          <ul className="max-w-3xl mx-auto pl-12">
            <Accordion title="Why should I use this app?" active>
              Have you had an expereince where your patient lost their
              prescription? Have you wondered how beneficial it will be for the
              patient if you had their medical history? Are your over-whelmed
              with the setup it requires to adapt existing EMR solutions. Our
              easy-to-use mobile based solution allows you to add your patients'
              records. We digitize your medical records for you and help you
              setup patient appointments. We send SMS to your patients on your
              behalf.
            </Accordion>
            <Accordion
              title="Does your solution really work without a laptop or the internet?"
              active
            >
              Yes. We heard your feedback. We understand that internet is not
              reliable throughout Pakistan. We also understand that most doctors
              do not have laptops at their clinics. Our solution works remotely,
              like WhatsApp. You can add patient records even when internet is
              not available. We will sync your data to the cloud when you
              connect to the internet so that your data is safely backed up.
            </Accordion>
            <Accordion title="What medical speciality is this for?" active>
              We support all medical and surgical specialities. If you deal with
              patients, this app is for you.
            </Accordion>
            <Accordion title="How does the scribe help me?" active>
              We have a team of experienced remote scribes that use their field
              experience and machine learning tools to digitize your records.
            </Accordion>
            <Accordion title="How does the scheduer help me?" active>
              We have a team of experienced remote schedulers who can text or
              call your patients who have missed a scheduled meeting and help
              them setup an appointment with you.
            </Accordion>
            <Accordion title="Do you have a referral program?" active>
              Yes, we do! Refer our app to your friends and collegues and when
              they sign up, you receive a discount on your subscription.
              <ShareSocialMedia />
            </Accordion>
            <Accordion
              title="What do you offer that other EMR solutions do not?"
              active
            >
              Current EMR systems in Pakistan do not provide an offline
              mobile-based solution. We are also the first ones to provide a
              remote scribe + scheduler service to our doctors. We also offer a
              comprehensive list of features in our app that are missing in
              existing EMR solutions.
            </Accordion>
            <Accordion title="How do I start?" active>
              Give us a call at{" "}
              <a href="tel:03361118422">
                <span className="font-bold">0336-111-8422</span>
              </a>{" "}
              and we will help your transform your medical practice and answer
              your questions.
            </Accordion>
            <span
              className="block border-t border-gray-200"
              aria-hidden="true"
            ></span>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Faqs;
