import React, { useState } from "react";

function TestimonialsCircles() {
  const [commentOn, setCommentOn] = useState(false);
  const [active, setActive] = useState(0);
  const [items] = useState([
    {
      // user-1.jpg
      size: "48", // image width / height
      style: { maxWidth: "7.08%", top: "29%", left: "2%" }, // image absolute  positioning
      comment: "What an app 1! 🙌", // comment to be displayed on hover,
      name: "Allama Iqbal",
    },
    {
      // user-2.jpg
      size: "36",
      style: { maxWidth: "5.31%", top: "60%", left: "2%" },
      comment: "What an app 2! 🙌",
      name: "Allama Iqbal",
    },
    {
      // user-3.jpg
      size: "72",
      style: { maxWidth: "10.62%", top: "43%", left: "13%" },
      comment: "What an app 3! 🙌",
      name: "Allama Iqbal",
    },
    {
      // user-4.jpg
      size: "56",
      style: { maxWidth: "8.26%", top: "75%", left: "14%" },
      comment: "What an app 4! 🙌",
      name: "Allama Iqbal",
    },
    {
      // user-5.jpg
      size: "64",
      style: { maxWidth: "9.44%", top: "11%", left: "15%" },
      comment: "What an app 5! 🙌",
      name: "Allama Iqbal",
    },
    {
      // user-6.jpg
      size: "80",
      style: { maxWidth: "11.8%", top: "32%", left: "30%" },
      comment: "What an app 6! 🙌",
      name: "Allama Iqbal",
    },
    {
      // user-7.jpg
      size: "50",
      style: { maxWidth: "7.37%", top: "65%", left: "30%" },
      comment: "What an app 7! 🙌",
      name: "Allama Iqbal",
    },
    {
      // user-8.jpg
      size: "36",
      style: { maxWidth: "5.31%", top: "6%", left: "33%" },
      comment: "What an app 8! 🙌",
      name: "Allama Iqbal",
    },
    {
      // user-9.jpg
      size: "44",
      style: { maxWidth: "6.49%", top: "77%", left: "44%" },
      comment: "What an app 9! 🙌",
      name: "Allama Iqbal",
    },
    {
      // user-10.jpg
      size: "64",
      style: { maxWidth: "9.44%", top: "48%", left: "46%" },
      comment: "What an app 10! 🙌",
      name: "Allama Iqbal",
    },
    {
      // user-11.jpg
      size: "72",
      style: { maxWidth: "10.62%", top: "16%", left: "46%" },
      comment: "What an app 11! 🙌",
      name: "Allama Iqbal",
    },
    {
      // user-12.jpg
      size: "64",
      style: { maxWidth: "9.44%", top: "65%", left: "58%" },
      comment: "What an app 12! 🙌",
      name: "Allama Iqbal",
    },
    {
      // user-13.jpg
      size: "80",
      style: { maxWidth: "11.8%", top: "32%", left: "62%" },
      comment: "What an app 13! 🙌",
      name: "Allama Iqbal",
    },
    {
      // user-14.jpg
      size: "48",
      style: { maxWidth: "7.08%", top: "6%", left: "63%" },
      comment: "What an app 14! 🙌",
      name: "Allama Iqbal",
    },
    {
      // user-16.jpg
      size: "36",
      style: { maxWidth: "5.31%", top: "86%", left: "76%" },
      comment: "What an app 16! 🙌",
      name: "Allama Iqbal",
    },
    {
      // user-17.jpg
      size: "44",
      style: { maxWidth: "6.49%", top: "59%", left: "76%" },
      comment: "What an app 17! 🙌",
      name: "Allama Iqbal",
    },
    {
      // user-18.jpg
      size: "72",
      style: { maxWidth: "10.62%", top: "14%", left: "78%" },
      comment: "What an app 18! 🙌",
      name: "Allama Iqbal",
    },
    {
      // user-19.jpg
      size: "64",
      style: { maxWidth: "9.44%", top: "40%", left: "89%" },
      comment: "What an app 19! 🙌",
      name: "Allama Iqbal",
    },
  ]);

  return (
    <section>
      <div className="max-w-3xl mx-auto px-4 sm:px-6">
        <div className="pb-12 md:pb-20">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-20">
            <h2 className="h2">Reviews</h2>
          </div>
          <div className="relative">
            {/* Background image */}
            <svg
              className="mx-auto"
              viewBox="0 0 678 346"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <radialGradient
                  cx="50%"
                  cy="50%"
                  fx="50%"
                  fy="50%"
                  r="39.386%"
                  id="circle_b"
                >
                  <stop stopColor="#3ABAB4" offset="0%" />
                  <stop stopColor="#3ABAB4" stopOpacity="0" offset="100%" />
                </radialGradient>
                <linearGradient
                  x1="50%"
                  y1="50%"
                  x2="50%"
                  y2="89.386%"
                  id="circle_a"
                >
                  <stop stopColor="#2E2E33" offset="0%" />
                  <stop stopColor="#2E2E33" stopOpacity="0" offset="100%" />
                </linearGradient>
              </defs>
              <g fill="none" fillRule="evenodd">
                <circle
                  className="opacity-10 dark:opacity-100"
                  fill="url(#circle_a)"
                  opacity=".32"
                  cx="339"
                  cy="173"
                  r="173"
                />
                <circle
                  fill="url(#circle_b)"
                  opacity=".32"
                  cx="339"
                  cy="173"
                  r="140"
                />
              </g>
            </svg>

            {/* People pics */}
            {items.map((item, index) => (
              <img
                key={index}
                className="absolute rounded-full z-10 animate-float"
                style={item.style}
                src={require(`../images//user-${index + 1}.jpg`).default}
                width={item.size}
                height={item.size}
                alt={`User ${index + 1}`}
                onMouseEnter={() => {
                  setActive(index);
                  setCommentOn(true);
                }}
                onMouseLeave={() => setCommentOn(false)}
              />
            ))}

            {/* Comment box */}
            <div
              className={`opacity-0 transition duration-150 ease-in-out absolute top-0 left-1/2 transform -translate-x-1/2 -mt-6 max-w-xs w-full p-3 bg-white dark:bg-gray-800 text-center shadow-2xl z-20 pointer-events-none ${
                commentOn && "opacity-100"
              }`}
            >
              <div className="text-gray-600 dark:text-gray-400 mb-1">
                {items[active].comment}
              </div>
              <div className="text-sm font-bold text-gray-800 dark:text-gray-100">
                {items[active].name}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TestimonialsCircles;
