import React from "react";

function Testimonials() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-transparent dark:border-gray-800">
          {/* Testimonials */}
          <div className="max-w-3xl mx-auto text-center pb-20">
            <h2 className="h2">What our customers are saying about us</h2>
          </div>
          <div className="max-w-sm mx-auto grid gap-x-8 gap-y-12 sm:grid-cols-2 lg:gap-12 items-start sm:max-w-none md:max-w-2xl lg:max-w-none">
            {/* 1st testimonial */}
            <div className="text-center">
              <div className="relative inline-flex flex-col mb-4">
                <img
                  className="rounded-full"
                  src={require("../images/testimonial-01.jpeg").default}
                  width="56"
                  height="56"
                  alt="Testimonial 01"
                />
                <svg
                  className="absolute top-0 right-0 mt-1 -mr-8"
                  width="27"
                  height="12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="fill-current text-blue-500"
                    d="M2.785 5.334C2.538 5.5-.2 2.944.011 2.646.826 1.483 2.183.836 3.62.5 5.064.158 6.582.117 7.92-.02c.017-.002.098.153.088.166-1.763 2.018-3.223 3.836-5.221 5.188zm3.676 6.519c-.862.184-1.937-3.403-1.07-3.711 3.422-1.22 7.078-1.671 10.728-1.766 3.655-.096 7.304.162 10.866.32.044.002.06.177.018.187-6.938 1.634-13.691 3.504-20.542 4.97z"
                  />
                </svg>
              </div>
              <blockquote className="text-xl text-gray-600 dark:text-gray-400">
                “ A very easy to use app that helps keep track of patient
                records as well as digitalizes it. A very useful tool for any
                physician. “
              </blockquote>
              <div className="font-red-hat-display mt-2">
                <cite className="not-italic">
                  <span className="font-bold">— Dr. Eraj Mehdi</span>
                  <span className="">, Medicine, Faisalabad</span>
                </cite>
              </div>
            </div>

            {/* 2nd testimonial */}
            <div className="text-center">
              <div className="relative inline-flex flex-col mb-4">
                <img
                  className="rounded-full"
                  src={require("../images/testimonial-02.jpg").default}
                  width="56"
                  height="56"
                  alt="Testimonial 02"
                />
                <svg
                  className="absolute top-0 right-0 mt-1 -mr-8"
                  width="27"
                  height="12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="fill-current text-blue-500"
                    d="M2.785 5.334C2.538 5.5-.2 2.944.011 2.646.826 1.483 2.183.836 3.62.5 5.064.158 6.582.117 7.92-.02c.017-.002.098.153.088.166-1.763 2.018-3.223 3.836-5.221 5.188zm3.676 6.519c-.862.184-1.937-3.403-1.07-3.711 3.422-1.22 7.078-1.671 10.728-1.766 3.655-.096 7.304.162 10.866.32.044.002.06.177.018.187-6.938 1.634-13.691 3.504-20.542 4.97z"
                  />
                </svg>
              </div>
              <blockquote className="text-xl text-gray-600 dark:text-gray-400 font-urdu">
                “ میں چالیس سال سے پاکستان میں میڈیسن پریکٹس کر رہا ہوں اور میری
                ہمیشہ سے خواہش رہی ہے کہ میں آسانی سےاپنے مریضوں کا ریکارڈ رکھ
                سکوں . اس اپپ کی مدد سے اب یہ ممکن ہو گیا ہے . میں اس کاوش کو
                بہت سراہتا ہوں “
              </blockquote>
              <div className="font-red-hat-display mt-2">
                <cite className="not-italic">
                  <span className="font-bold">— Dr. Zaheer Ahmad</span>
                  <span className="">, Pediatrician, Faisalabad</span>
                </cite>
              </div>
            </div>

            {/* 3rd testimonial */}
            <div className="text-center">
              <div className="relative inline-flex flex-col mb-4">
                <img
                  className="rounded-full"
                  src={require("../images/testimonial-03.jpg").default}
                  width="56"
                  height="56"
                  alt="Testimonial 03"
                />
                <svg
                  className="absolute top-0 right-0 mt-1 -mr-8"
                  width="27"
                  height="12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="fill-current text-blue-500"
                    d="M2.785 5.334C2.538 5.5-.2 2.944.011 2.646.826 1.483 2.183.836 3.62.5 5.064.158 6.582.117 7.92-.02c.017-.002.098.153.088.166-1.763 2.018-3.223 3.836-5.221 5.188zm3.676 6.519c-.862.184-1.937-3.403-1.07-3.711 3.422-1.22 7.078-1.671 10.728-1.766 3.655-.096 7.304.162 10.866.32.044.002.06.177.018.187-6.938 1.634-13.691 3.504-20.542 4.97z"
                  />
                </svg>
              </div>
              <blockquote className="text-xl text-gray-600 dark:text-gray-400">
                “ I have used multiple EMR solutions available in Pakistan and
                by far, this is the best and most easy to use solution out
                there. Would highly recommend to other physicians and surgeons! “
              </blockquote>
              <div className="font-red-hat-display mt-2">
                <cite className="not-italic">
                  <span className="font-bold">— Dr. Someone Else</span>
                  <span className="">, Urologist, Lahore</span>
                </cite>
              </div>
            </div>

            {/* 4th testimonial */}
            <div className="text-center">
              <div className="relative inline-flex flex-col mb-4">
                <img
                  className="rounded-full"
                  src={require("../images/testimonial-04.jpg").default}
                  width="56"
                  height="56"
                  alt="Testimonial 04"
                />
                <svg
                  className="absolute top-0 right-0 mt-1 -mr-8"
                  width="27"
                  height="12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="fill-current text-blue-500"
                    d="M2.785 5.334C2.538 5.5-.2 2.944.011 2.646.826 1.483 2.183.836 3.62.5 5.064.158 6.582.117 7.92-.02c.017-.002.098.153.088.166-1.763 2.018-3.223 3.836-5.221 5.188zm3.676 6.519c-.862.184-1.937-3.403-1.07-3.711 3.422-1.22 7.078-1.671 10.728-1.766 3.655-.096 7.304.162 10.866.32.044.002.06.177.018.187-6.938 1.634-13.691 3.504-20.542 4.97z"
                  />
                </svg>
              </div>
              <blockquote className="text-xl text-gray-600 dark:text-gray-400">
                “ The fact that I can use this app offline is a game changer! “
              </blockquote>
              <div className="font-red-hat-display mt-2">
                <cite className="not-italic">
                  <span className="font-bold">— Dr. Usman Ghumman</span>
                  <span className="">, Cardiologist, Faisalabad</span>
                </cite>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
