import React, { useState } from "react";
import axios from "axios";

function Cta() {
  const [isPromptSubmitted, setPromptSubmitted] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");
  const [serverResponseText, setServerResponseText] = useState("");
  const [serverResponseColor, setServerResponseColor] = useState(
    "text-green-800"
  );

  const onSubmit = (evt) => {
    evt.preventDefault();

    if (name.replace(/ /g, "").length == 0) {
      setError("Please enter your first name");
      return;
    }
    if (phone.replace(/ /g, "").length == 0) {
      setError("Please enter your phone");
      return;
    }

    axios
      .post(
        `https://rxplqw36gc.execute-api.us-east-1.amazonaws.com/prod/request-demo`,
        {
          firstName: name,
          phone: phone,
        }
      )
      .then((res) => {
        setServerResponseText(
          "Request submitted! Someone in our team will contact you shortly."
        );
        setServerResponseColor("text-green-800");
      })
      .catch(function (error) {
        setServerResponseText(
          "Error connecting to server. Please call us instead!"
        );
        setServerResponseColor("text-red-800");
      });

    setPromptSubmitted(true);
  };

  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        {/* CTA box */}
        <div className="relative py-10 px-8 md:py-16 md:px-12 rounded shadow-xl">
          {/* Background illustration */}
          <div
            className="absolute inset-0 left-auto pointer-events-none"
            aria-hidden="true"
          >
            <svg
              className="h-full"
              width="400"
              height="232"
              viewBox="0 0 400 232"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <radialGradient
                  // cx="50%"
                  // cy="50%"
                  // fx="50%"
                  // fy="50%"
                  // r="39.386%"
                  id="box-gr-a"
                >
                  {/* <stop stopColor="#667EEA" offset="0%" />
                  <stop stopColor="#667EEA" stopOpacity="0" offset="100%" /> */}
                  <stop stopColor="#AA3366" offset="0%" stopOpacity="100%" />
                  <stop stopColor="#AA3366" stopOpacity="0" offset="100%" />
                  {/* <stop offset="10%" stop-color="blue" /> */}
                  {/* <stop offset="95%" stop-color="blue" /> */}
                </radialGradient>
                <radialGradient
                  cx="50%"
                  cy="50%"
                  fx="50%"
                  fy="50%"
                  r="39.386%"
                  id="box-gr-b"
                >
                  {/* <stop stopColor="#3ABAB4" offset="0%" />
                  <stop stopColor="#3ABAB4" stopOpacity="0" offset="100%" /> */}
                  <stop stopColor="#CC0000" offset="0%" />
                  <stop stopColor="#CC0000" stopOpacity="0" offset="100%" />
                </radialGradient>
              </defs>
              <g transform="translate(-85 -369)" fill="none" fillRule="evenodd">
                <circle
                  fillOpacity=".16"
                  fill="url(#box-gr-a)"
                  cx="413"
                  cy="688"
                  r="240"
                />
                <circle
                  fillOpacity=".5"
                  fill="url(#box-gr-b)"
                  cx="400"
                  cy="400"
                  r="400"
                />
              </g>
            </svg>
          </div>

          <div className="relative flex flex-col lg:flex-row justify-between items-center">
            {/* CTA content */}
            <div className="mb-6 lg:mr-16 lg:mb-0 text-center lg:text-left lg:w-1/2">
              {/* <h3 className="h3 font-red-hat-display text-black-100">Request a Demo</h3> */}
              <div className="font-architects-daughter text-xl text-red-600 mb-2">
                Still not convinced?
              </div>
              <h3 className="h3 mb-4">Request a demo</h3>
            </div>

            {/* CTA form */}
            <form className="w-full lg:w-2/3" onSubmit={onSubmit}>
              {!isPromptSubmitted && (
                <div>
                  <div className="flex flex-col sm:flex-row justify-center max-w-xs mx-auto sm:max-w-md lg:max-w-none">
                    <input
                      type="text"
                      className="form-input w-full mb-2 sm:mb-0 sm:mr-2"
                      placeholder="First Name"
                      aria-label="First Name"
                      value={name}
                      onChange={(e) => {
                        setError("");
                        setName(e.target.value);
                      }}
                    />
                    <input
                      type="tel"
                      className="form-input w-full mb-2 sm:mb-0 sm:mr-2"
                      placeholder="Phone Number"
                      aria-label="Phone number"
                      value={phone}
                      onChange={(e) => {
                        setError("");
                        setPhone(e.target.value);
                      }}
                    />
                    <input
                      className="btn text-white bg-blue-500 hover:bg-blue-400 flex-shrink-0 cursor-pointer"
                      type="submit"
                    />
                  </div>
                  {error && <span className="text-red-900">{error}</span>}
                </div>
              )}
              {/* Success message */}
              {isPromptSubmitted && (
                <div className="">
                  <p
                    className={`text-center lg:text-left ${serverResponseColor} lg:absolute opacity-75 text-sm`}
                  >
                    {serverResponseText}
                  </p>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Cta;
