import React from "react";

const svgPhone = [
  "M12.9665 7.00504C12.9637 5.34818 14.3047 4.00284 15.9615 4.00013L31.9337 3.97397C33.5906 3.97125 34.9359 5.3122 34.9386 6.96905L34.9943 40.9559C34.997 42.6128 33.6561 43.9581 31.9992 43.9608L16.027 43.987C14.3702 43.9897 13.0248 42.6488 13.0221 40.9919L12.9665 7.00504ZM14.9656 8.00168C14.9638 6.89711 15.8577 6.00021 16.9623 5.9984L30.9395 5.97551C32.0441 5.9737 32.941 6.86766 32.9428 7.97223L32.987 34.9621C32.9888 36.0666 32.0949 36.9635 30.9903 36.9653L17.0131 36.9882C15.9085 36.99 15.0116 36.0961 15.0098 34.9915L14.9656 8.00168ZM24.0099 41.9745C25.1131 41.9727 26.0059 41.0761 26.0041 39.9719C26.0023 38.8677 25.1065 37.974 24.0033 37.9758C22.9002 37.9776 22.0073 38.8742 22.0091 39.9784C22.0109 41.0826 22.9067 41.9763 24.0099 41.9745Z",
  "M23.1923 27.5397L23.1921 27.5395C21.6033 26.2237 20.297 25.1418 19.3858 24.1243C18.4764 23.1088 17.9766 22.1747 17.9758 21.1568C17.9745 19.5006 19.3937 18.2 21.2042 18.1985C22.2283 18.1977 23.2141 18.6337 23.8572 19.3235L23.9744 19.4492L24.0914 19.3233C24.7334 18.6325 25.7184 18.1948 26.7426 18.194C28.553 18.1925 29.9744 19.4907 29.9758 21.147C29.9766 22.1648 29.4785 23.0998 28.5707 24.1175C27.6642 25.1337 26.3654 26.2153 24.7861 27.5305L24.7702 27.5437L24.7699 27.544L23.982 28.1963L23.1923 27.5397Z",
];

const svgOffline = [
  "M34.7,36.1c0.5-0.5,0.5-1.3,0-1.8l-1.8-1.8c-0.5-0.5-1.3-0.5-1.8,0l-4.4,4.4c-0.3,0.3-0.9,0.3-1.2,0l-4.4-4.4c-0.5-0.5-1.3-0.5-1.8,0l-1.8,1.8c-0.5,0.5-0.5,1.3,0,1.8l4.4,4.4c0.3,0.3,0.3,0.9,0,1.2l-4.4,4.4c-0.5,0.5-0.5,1.3,0,1.8l1.8,1.8c0.5,0.5,1.3,0.5,1.8,0l4.4-4.4c0.3-0.3,0.9-0.3,1.2,0l4.4,4.4c0.5,0.5,1.3,0.5,1.8,0l1.8-1.8c0.5-0.5,0.5-1.3,0-1.8l-4.4-4.4c-0.3-0.3-0.3-0.9,0-1.2L34.7,36.1z",
  "M47.7,11.6c-5.5-6.1-13.3-9.5-21.6-9.5S10,5.5,4.5,11.6C4.1,12,4.2,12.7,4.6,13l3,2.6C8,16,8.6,15.9,9,15.5c4.4-4.7,10.6-7.4,17.1-7.4s12.7,2.7,17.1,7.4c0.4,0.4,1,0.4,1.4,0.1l3-2.6C48,12.6,48.1,12,47.7,11.6z",
  "M26.1,16.1c-4.2,0-8.2,1.8-11,5c-0.4,0.4-0.3,1.1,0.1,1.5l3.2,2.4c0.4,0.3,1,0.3,1.3-0.1c1.7-1.8,4-2.8,6.4-2.8s4.7,1,6.3,2.7c0.3,0.4,0.9,0.4,1.3,0.1l3.2-2.4c0.5-0.4,0.5-1,0.1-1.5C34.3,17.9,30.3,16.1,26.1,16.1z",
];

const svgEMR = [
  "M28.71,10.29,19.29.88A3,3,0,0,0,17.17,0H6A3,3,0,0,0,3,3V25a3,3,0,0,0,3,3H7v1a3,3,0,0,0,3,3H26a3,3,0,0,0,3-3V11A1,1,0,0,0,28.71,10.29ZM23,7.41,25.59,10H23ZM6,26a1,1,0,0,1-1-1V3A1,1,0,0,1,6,2H17.17a1,1,0,0,1,.71.29L19.59,4H10A3,3,0,0,0,7,7V26Zm20,4H10a1,1,0,0,1-1-1V7a1,1,0,0,1,1-1H21v5a1,1,0,0,0,1,1h5V29A1,1,0,0,1,26,30Z",
  "M20,27H16a1,1,0,0,1-1-1V24H13a1,1,0,0,1-1-1V19a1,1,0,0,1,1-1h2V16a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1v2h2a1,1,0,0,1,1,1v4a1,1,0,0,1-1,1H21v2A1,1,0,0,1,20,27Zm-3-2h2V23a1,1,0,0,1,1-1h2V20H20a1,1,0,0,1-1-1V17H17v2a1,1,0,0,1-1,1H14v2h2a1,1,0,0,1,1,1Z",
];

const svgAssistant = [
  "M312 8C175 8 64 119 64 256s111 248 248 248 248-111 248-248c0-25.38-3.82-49.86-10.9-72.91l63.92 52.97L640 173.49l-98.2-10.89c-.65-1.6-1.31-3.19-2-4.77l94.89-40.43L595 61.64l-60.41 84.91C494.17 64.46 409.7 8 312 8zM191.99 256c0-9.3 4.1-17.5 10.5-23.4l-31-9.3c-8.5-2.5-13.3-11.5-10.7-19.9 2.5-8.5 11.4-13.2 19.9-10.7l80 24c8.5 2.5 13.3 11.5 10.7 19.9-2.1 6.9-8.4 11.4-15.3 11.4-.5 0-1.1-.2-1.7-.2.7 2.7 1.7 5.3 1.7 8.2 0 17.7-14.3 32-32 32s-32.1-14.3-32.1-32zm252.61-32.7l-31 9.3c6.3 5.8 10.5 14.1 10.5 23.4 0 17.7-14.3 32-32 32s-32-14.3-32-32c0-2.9.9-5.6 1.7-8.2-.6.1-1.1.2-1.7.2-6.9 0-13.2-4.5-15.3-11.4-2.5-8.5 2.3-17.4 10.7-19.9l80-24c8.4-2.5 17.4 2.3 19.9 10.7 2.5 8.5-2.3 17.4-10.8 19.9zm-265.19-52.28h262.56c42.35 0 54.97 49.74 53.8 83.99-1.18 34.83-41.79 72.53-72.23 72.53-61.58 0-73.62-40.25-112.85-40.28-39.23.03-51.27 40.28-112.85 40.28-30.44 0-71.05-37.7-72.23-72.53-1.17-34.25 11.45-83.99 53.8-83.99z",
];

const svgAppointment = [
  "M28,4H27V3a3,3,0,0,0-6,0V4H19V3a3,3,0,0,0-6,0V4H11V3A3,3,0,0,0,5,3V4H4A3,3,0,0,0,1,7V29a3,3,0,0,0,3,3H28a3,3,0,0,0,3-3V7A3,3,0,0,0,28,4ZM23,3a1,1,0,0,1,2,0V4H23ZM15,3a1,1,0,0,1,2,0V4H15ZM7,3A1,1,0,0,1,9,3V4H7ZM3,7A1,1,0,0,1,4,6H5V7A1,1,0,0,0,7,7V6h6V7a1,1,0,0,0,2,0V6h6V7a1,1,0,0,0,2,0V6h5a1,1,0,0,1,1,1v3H3ZM29,29a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V12H29Z",
  "M18,28H14a1,1,0,0,1-1-1V24H10a1,1,0,0,1-1-1V19a1,1,0,0,1,1-1h3V15a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1v3h3a1,1,0,0,1,1,1v4a1,1,0,0,1-1,1H19v3A1,1,0,0,1,18,28Zm-3-2h2V23a1,1,0,0,1,1-1h3V20H18a1,1,0,0,1-1-1V16H15v3a1,1,0,0,1-1,1H11v2h3a1,1,0,0,1,1,1Z",
];

const svgUpload = [
  "M274.107,130.68c-14.646-26.151-43.487-41.154-74.424-36.215c-12.147-25.487-37.536-41.673-66.098-41.673c-40.421,0-73.318,32.897-73.318,73.334c0,1.666,0.066,3.386,0.216,5.17C25.593,139.254,0,170.75,0,207.025c0,42.88,34.879,77.756,77.753,77.756h57.604h3.849h14.382v-86.235l-10.863,10.863c-5.702,5.705-15.646,5.705-21.344,0c-2.846-2.847-4.428-6.642-4.428-10.671c0-4.035,1.576-7.812,4.435-10.658l36.635-36.653c2.798-2.801,6.689-4.407,10.661-4.407l1.465,0.048c3.54,0.285,6.836,1.802,9.395,4.341l36.659,36.671c2.852,2.847,4.425,6.63,4.425,10.665c0,4.022-1.573,7.83-4.432,10.676c-5.68,5.699-15.642,5.699-21.341,0l-11.085-11.103v86.469h76.057c42.875,0,77.751-34.882,77.751-77.756C337.578,169.717,310.515,137.471,274.107,130.68z",
];

function FeaturesBlocks() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-800">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <div className="inline-flex text-sm font-semibold py-1 px-3 m-2 text-green-600 bg-green-200 rounded-full mb-4">
              Built for Pakistan
            </div>
            <h2 className="h2 mb-4">The Ultimate Patient Management App</h2>
            <p className="text-xl text-gray-400">
              Our solution helps you with every aspect of managing your
              patients.
            </p>
          </div>

          {/* Items */}
          <div
            className="max-w-sm mx-auto grid gap-8 md:grid-cols-2 lg:grid-cols-3 lg:gap-16 items-start md:max-w-2xl lg:max-w-none"
            data-aos-id-blocks
          >
            {/* 1st item */}
            <div
              className="relative flex flex-col items-center rounded bg-white shadow-xl px-5 pb-5"
              data-aos="fade-up"
              data-aos-anchor="[data-aos-id-blocks]"
            >
              <svg
                className="w-16 h-16 mb-4"
                viewBox="0 0 64 64"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  className="fill-current text-blue-600"
                  width="64"
                  height="64"
                  rx="32"
                />
                {svgPhone.map(function (data, index) {
                  return (
                    <path
                      key={"svgPhone-" + index}
                      transform="translate(6, 5), scale(1.1)"
                      d={data}
                      strokeLinecap="square"
                      strokeWidth="0"
                      fill="white"
                      fillRule="evenodd"
                    />
                  );
                })}
              </svg>
              <h4 className="h4 mb-2">Mobile-First</h4>
              <p className="text-lg text-gray-400 text-center">
                Manage all aspects of your medical practice from your phone.
              </p>
            </div>

            {/* 2nd item */}
            <div
              className="relative flex flex-col items-center rounded bg-white shadow-xl px-5 pb-5"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-anchor="[data-aos-id-blocks]"
            >
              <svg
                className="w-16 h-16 mb-4"
                viewBox="0 0 64 64"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  className="fill-current text-blue-600"
                  cx="32"
                  cy="32"
                  r="32"
                />
                {svgOffline.map(function (data, index) {
                  return (
                    <path
                      key={"svgOffline-" + index}
                      transform="translate(9, 9), scale(0.9)"
                      d={data}
                      strokeLinecap="square"
                      strokeWidth="0"
                      fill="white"
                      fillRule="evenodd"
                    />
                  );
                })}
              </svg>
              <h4 className="h4 mb-2">Offline-First</h4>
              <p className="text-lg text-gray-400 text-center">
                Our app works offline. We make sure your workflow is not
                affected if internet is not available.
              </p>
            </div>

            {/* 3rd item */}
            <div
              className="relative flex flex-col items-center rounded bg-white shadow-xl px-5 pb-5"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-anchor="[data-aos-id-blocks]"
            >
              <svg
                className="w-16 h-16 mb-4"
                viewBox="0 0 64 64"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  className="fill-current text-blue-600"
                  width="64"
                  height="64"
                  rx="32"
                />
                {svgEMR.map(function (data, index) {
                  return (
                    <path
                      key={"svgEMR-" + index}
                      transform="translate(13, 12), scale(1.2)"
                      d={data}
                      strokeLinecap="square"
                      strokeWidth="0"
                      fill="white"
                      fillRule="evenodd"
                    />
                  );
                })}
              </svg>
              <h4 className="h4 mb-2">Medical Records</h4>
              <p className="text-lg text-gray-400 text-center">
                Keep all your patients' medical records at your fingertips in
                one place.
              </p>
            </div>

            {/* 4th item */}
            <div
              className="relative flex flex-col items-center rounded bg-white shadow-xl px-5 pb-5"
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-anchor="[data-aos-id-blocks]"
            >
              <svg
                className="w-16 h-16 mb-4"
                viewBox="0 0 64 64"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  className="fill-current text-blue-600"
                  width="64"
                  height="64"
                  rx="32"
                />

                {svgAssistant.map(function (data, index) {
                  return (
                    <path
                      key={"svgAssistant-" + index}
                      transform="translate(6, 11), scale(0.08)"
                      d={data}
                      strokeLinecap="square"
                      stroke="red"
                      strokeWidth="0"
                      fill="white"
                      fillRule="evenodd"
                    />
                  );
                })}
              </svg>
              <h4 className="h4 mb-2">Ninjas at Work!</h4>
              <p className="text-lg text-gray-400 text-center">
                Our team of Schedulers and Scribes work round the clock to
                super-charge your practice.
              </p>
            </div>

            {/* 5th item */}
            <div
              className="relative flex flex-col items-center rounded bg-white shadow-xl px-5 pb-5"
              data-aos="fade-up"
              data-aos-delay="400"
              data-aos-anchor="[data-aos-id-blocks]"
            >
              <svg
                className="w-16 h-16 mb-4"
                viewBox="0 0 64 64"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  className="fill-current text-blue-600"
                  width="64"
                  height="64"
                  rx="32"
                />
                {svgAppointment.map(function (data, index) {
                  return (
                    <path
                      key={"svgAppointment-" + index}
                      transform="translate(13, 12), scale(1.2)"
                      d={data}
                      strokeLinecap="square"
                      strokeWidth="0"
                      fill="white"
                      fillRule="evenodd"
                    />
                  );
                })}
              </svg>
              <h4 className="h4 mb-2">Appointments</h4>
              <p className="text-lg text-gray-400 text-center">
                Keep track of your upcoming appointments and schedule new
                appointments.
              </p>
            </div>

            {/* 6th item */}
            <div
              className="relative flex flex-col items-center rounded bg-white shadow-xl px-5 pb-5"
              data-aos="fade-up"
              data-aos-delay="500"
              data-aos-anchor="[data-aos-id-blocks]"
            >
              <svg
                className="w-16 h-16 mb-4"
                viewBox="0 0 64 64"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  className="fill-current text-blue-600"
                  width="64"
                  height="64"
                  rx="32"
                />
                {svgUpload.map(function (data, index) {
                  return (
                    <path
                      key={"svgUpload-" + index}
                      transform="translate(10, 9), scale(0.13)"
                      d={data}
                      strokeLinecap="square"
                      strokeWidth="0"
                      fill="white"
                      fillRule="evenodd"
                    />
                  );
                })}
              </svg>
              <h4 className="h4 mb-2">Cloud Backup</h4>
              <p className="text-lg text-gray-400 text-center">
                Losing your phone does not mean losing your data. We backup your
                data to the cloud.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeaturesBlocks;
