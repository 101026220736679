import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-16 border-t border-gray-200 dark:border-gray-800 -mt-px">
          {/* Footer illustration */}
          <div className="pointer-events-none -z-1" aria-hidden="true">
            <svg
              className="absolute bottom-0 left-0 transform -translate-x-1/2 ml-24 dark:opacity-40"
              width="800"
              height="264"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="400"
                cy="400"
                r="400"
                fill="url(#footerglow_paint0_radial)"
                fillOpacity=".4"
              />
              <defs>
                <radialGradient
                  id="footerglow_paint0_radial"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="rotate(90 0 400) scale(315.089)"
                >
                  <stop stopColor="#3ABAB4" />
                  <stop offset="1" stopColor="#3ABAB4" stopOpacity=".01" />
                </radialGradient>
              </defs>
            </svg>
          </div>

          {/* Top area: Blocks */}
          <div className="grid md:grid-cols-12 lg:gap-20 mb-8 md:mb-12">
            {/* 1st block */}
            <div className="md:col-span-2 lg:col-span-3">
              {/* Download Apps */}
              <div className="flex space-x-2">
                <a
                  className=""
                  href="https://play.google.com/store?hl=en_US&gl=US"
                  target="_blank"
                >
                  <img
                    className=""
                    src={require("../images/google.png").default}
                    alt="Play Store"
                  />
                </a>
                <a
                  className=""
                  href="https://www.apple.com/app-store/"
                  target="_blank"
                >
                  <img
                    className=""
                    src={require("../images/apple.png").default}
                    alt="App Store"
                  />
                </a>
              </div>
            </div>
          </div>

          {/* Bottom area */}
          <div className="md:flex md:items-center md:justify-between">
            {/* Social links */}
            <ul className="flex mb-4 md:order-2 md:ml-4 md:mb-0">
              <li>
                <a
                  className="flex justify-center items-center text-white bg-blue-500 dark:text-blue-500 dark:bg-gray-800 hover:underline hover:bg-blue-600 rounded-full transition duration-150 ease-in-out"
                  href="https://www.facebook.com/tibb.pk"
                  target="_blank"
                  aria-label="Facebook"
                >
                  <svg
                    className="w-8 h-8 fill-current"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z" />
                  </svg>
                </a>
              </li>
              {/* <li className="ml-4">
                <a
                  className="flex justify-center items-center text-white bg-teal-500 dark:text-teal-500 dark:bg-gray-800 hover:underline hover:bg-teal-600 rounded-full transition duration-150 ease-in-out"
                  href="#0"
                  aria-label="Facebook"
                >
                  <svg
                    className="w-8 h-8 fill-current"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z" />
                  </svg>
                </a>
              </li> */}
            </ul>

            {/* Middle links */}
            <div className="text-sm md:order-1 text-gray-700 mb-2 md:mb-0">
              <Link
                className="font-bold text-gray-600 dark:text-gray-400 hover:underline transition duration-150 ease-in-out"
                to="jobs/"
              >
                Jobs
              </Link>{" "}
              ·{" "}
              <Link
                className="font-bold text-gray-600 dark:text-gray-400 hover:underline transition duration-150 ease-in-out"
                to="terms/"
              >
                Terms
              </Link>{" "}
              ·{" "}
              <Link
                className="font-bold text-gray-600 dark:text-gray-400 hover:underline transition duration-150 ease-in-out"
                to="privacy/"
              >
                Privacy Policy
              </Link>
            </div>

            {/* Copyrights note */}
            <div className="text-gray-600 dark:text-gray-400 text-sm mr-4">
              Built with{" "}
              <span role="img" aria-label="sheep" color="red">
                💓
              </span>{" "}
              for Pakistan{" "}
              <span role="img" aria-label="sheep">
                🇵🇰
              </span>{" "}
            </div>

            {/* Copyrights note */}
            <div className="text-gray-600 dark:text-gray-400 text-sm mr-4">
              &copy; 2022 طب. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
