import React from "react";

import FeaturesAnimation from "./FeaturesAnimation";

function FeaturesZigzag() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-800">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <h2 className="h2 mb-4">Product Highlights</h2>
            <p className="text-xl text-gray-400">
              Following are some of the key features that we have to offer
            </p>
          </div>

          {/* Items */}
          <div className="grid gap-20">
            {/* 1st item */}
            <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
              {/* Image */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1"
                data-aos="fade-up"
              >
                <img
                  className="max-w-full mx-auto md:max-w-none h-auto rounded shadow-xl"
                  src={require("../images/features-03-image-01.jpg").default}
                  width="540"
                  height="405"
                  alt="Features 01"
                />
              </div>
              {/* Content */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6"
                data-aos="fade-right"
              >
                <div className="md:pr-4 lg:pr-12 xl:pr-16">
                  <div className="font-architects-daughter text-xl text-blue-600 mb-2">
                    Easy to use
                  </div>
                  <h3 className="h3 mb-3">
                    Add a Patient Record in just 5 clicks!
                  </h3>
                  <p className="text-xl text-gray-400 mb-4">
                    Have access to your patients' records on the go
                  </p>
                  <ul className="text-lg text-gray-400 -mb-2">
                    <li className="flex items-center mb-2">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>It takes 5 clicks to add a new patient visit</span>
                    </li>
                    <li className="flex items-center mb-2">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>
                        Avg. overhead per patient record is 20 seconds
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* 2nd item */}
            <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
              {/* Image */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:rtl"
                data-aos="fade-up"
              >
                <img
                  className="max-w-full mx-auto md:max-w-none h-auto rounded shadow-xl"
                  src={require("../images/features-03-image-02.jpg").default}
                  width="540"
                  height="405"
                  alt="Features 02"
                />
              </div>
              {/* Content */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6"
                data-aos="fade-left"
              >
                <div className="md:pl-4 lg:pl-12 xl:pl-16">
                  <div className="font-architects-daughter text-xl text-blue-600 mb-2">
                    Works Offline
                  </div>
                  <h3 className="h3 mb-3">No Laptop or Internet Required!</h3>
                  <p className="text-xl text-gray-400 mb-4">
                    Working in an area with unreliable internet? Don't have a
                    laptop at your office? We got you covered. Our solution does
                    not rely on laptop or internet
                  </p>
                </div>
              </div>
            </div>

            {/* 3rd item */}
            <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
              {/* Image */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1"
                data-aos="fade-up"
              >
                {/* <img
                  className="max-w-full mx-auto md:max-w-none h-auto rounded shadow-xl"
                  src={require("../images/features-03-image-03.jpg").default}
                  width="540"
                  height="405"
                  alt="Features 03"
                /> */}
                <FeaturesAnimation />
              </div>
              {/* Content */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6"
                data-aos="fade-right"
              >
                <div className="md:pr-4 lg:pr-12 xl:pr-16">
                  <div className="font-architects-daughter text-xl text-blue-600 mb-2">
                    Fully-Featured!
                  </div>
                  <h3 className="h3 mb-3">
                    Comprehensive Solution to your Medical Practice Needs!
                  </h3>
                  <p className="text-xl text-gray-400 mb-4">
                    We support adding detailed medical visits, lab reports,
                    imaging, patient medical and social history.
                  </p>
                </div>
              </div>
            </div>

            {/* 4th item */}
            <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
              {/* Image */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:rtl"
                data-aos="fade-up"
              >
                <img
                  className="max-w-full mx-auto md:max-w-none h-auto rounded shadow-xl"
                  src={require("../images/animation.gif").default}
                  width="540"
                  height="405"
                  alt="Features 02"
                />
              </div>
              {/* Content */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6"
                data-aos="fade-left"
              >
                <div className="md:pl-4 lg:pl-12 xl:pl-16">
                  <div className="font-architects-daughter text-xl text-blue-600 mb-2">
                    Tech Support
                  </div>
                  <h3 className="h3 mb-3">Let us do the heavy lifting!</h3>
                  <p className="text-xl text-gray-400 mb-4">
                    Let us digitize your records for you! Our team of remote
                    scribe will digitize your hand-written records and our
                    remote schedulers will assist in scheduling appointment.
                    This way you can do focus on what you do best!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeaturesZigzag;
