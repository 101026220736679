import React, { useEffect } from "react";

import Header from "../partials/Header";
import PageIllustration from "../partials/PageIllustration";
import HeroHome from "../partials/HeroHome";
import Stats from "../partials/Stats";
import Team from "../partials/Team";
import PricingTables from "../partials/PricingTables";
import TestimonialsBlocks from "../partials/TestimonialsBlocks";
import FeaturesBlocks from "../partials/FeaturesBlocks";
import Cta from "../partials/Cta";
import Footer from "../partials/Footer";
import Faqs from "../partials/Faqs";
import FeaturesZigzag from "../partials/FeaturesZigZag";
import Call from "../partials/Call";

function Home() {
  // Set light or dark mode
  const darkMode = false;
  useEffect(() => {
    localStorage.setItem("dark-mode", darkMode);
    if (darkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, []);

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      {/* <Header /> */}

      {/*  Page content */}
      <main className="flex-grow">
         {/* Page illustration */}
        <div
          className="relative max-w-6xl mx-auto h-0 pointer-events-none -z-1"
          aria-hidden="true"
        >
          <PageIllustration />
        </div>

        {/*  Page sections */}
        <HeroHome />
        <FeaturesBlocks />
        <FeaturesZigzag />
        <Stats />
        <PricingTables />
        <TestimonialsBlocks />
        <Faqs />
        <Team />
        <Cta />
      </main>

      {/*  Site footer */}
      <Footer />

      <Call />
    </div>
  );
}

export default Home;
