import React, { useEffect, useState } from "react";
import axios from "axios";

import Header from "../partials/Header";
import PageIllustration from "../partials/PageIllustration";
import Footer from "../partials/Footer";

function Jobs() {
  // Set light or dark mode
  const darkMode = false;
  useEffect(() => {
    localStorage.setItem("dark-mode", darkMode);
    if (darkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, []);
  const [isPromptSubmitted, setPromptSubmitted] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [details, setDetails] = useState("");
  const [serverResponseText, setServerResponseText] = useState("");
  const [serverResponseColor, setServerResponseColor] = useState(
    "text-green-800"
  );

  const onSubmit = (evt) => {
    evt.preventDefault();

    console.log("First Name: ", firstName);
    console.log("Last Name: ", lastName);
    console.log("Email: ", email);
    console.log("Phone: ", phone);
    console.log("Role: ", role);
    console.log("Details: ", details);

    axios
      .post(
        `https://rxplqw36gc.execute-api.us-east-1.amazonaws.com/prod/job-applicationX`,
        {
          firstName: firstName,
          lastName: lastName,
          email: email,
          phone: phone,
          role: role,
          details: details,
        }
      )
      .then((res) => {
        setServerResponseText(
          "Request submitted! Someone in our team will contact you shortly."
        );
        setServerResponseColor("text-green-800");
      })
      .catch(function (error) {
        console.log("Error: ");
        console.log(error);
        setServerResponseText(
          "Error connecting to server. Please call us instead!"
        );
        setServerResponseColor("text-red-800");
      });

    setPromptSubmitted(true);
  };

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      {/* <Header /> */}

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page illustration */}
        <div
          className="relative max-w-6xl mx-auto h-0 pointer-events-none -z-1"
          aria-hidden="true"
        >
          <PageIllustration />
        </div>

        <section className="relative">
          <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
            <div className="pt-10 pb-5">
              {/* Page header */}
              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                <h1 className="h1 font-red-hat-display mb-4">Careers</h1>
                <p className="text-xl text-gray-600 dark:text-gray-400">
                  Join us in building the best healthcare tools for Pakistani
                  doctors.
                </p>
              </div>

              {/* Contact form */}
              <form className="max-w-xl mx-auto" onSubmit={onSubmit}>
                {!isPromptSubmitted && (
                  <div>
                    <div className="flex flex-wrap -mx-3 mb-5">
                      <div className="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                        <label
                          className="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1"
                          htmlFor="first-name"
                        >
                          First Name <span className="text-red-600">*</span>
                        </label>
                        <input
                          id="first-name"
                          type="text"
                          className="form-input w-full"
                          placeholder="First Name"
                          required
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </div>
                      <div className="w-full md:w-1/2 px-3">
                        <label
                          className="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1"
                          htmlFor="last-name"
                        >
                          Last Name <span className="text-red-600">*</span>
                        </label>
                        <input
                          id="last-name"
                          type="text"
                          className="form-input w-full"
                          placeholder="Last Name"
                          required
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-5">
                      <div className="w-full px-3">
                        <label
                          className="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1"
                          htmlFor="phone"
                        >
                          Phone Number <span className="text-red-600">*</span>
                        </label>
                        <input
                          id="phone"
                          type="tel"
                          className="form-input w-full"
                          placeholder="Phone Number"
                          required
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-5">
                      <div className="w-full px-3">
                        <label
                          className="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1"
                          htmlFor="email"
                        >
                          Email <span className="text-red-600">*</span>
                        </label>
                        <input
                          id="email"
                          type="text"
                          className="form-input w-full"
                          placeholder="Email Address"
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-5">
                      <div className="w-full px-3">
                        <div className="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-3">
                          Which role are you interested in?
                        </div>
                        <label className="flex items-center mb-2">
                          <input
                            type="radio"
                            className="form-radio"
                            name="role"
                            value="software_engineer"
                            checked={role == "software_engineer"}
                            onChange={(e) => setRole(e.target.value)}
                          />
                          <span className="text-sm text-gray-600 dark:text-gray-400 ml-3">
                            Software Engineer
                          </span>
                        </label>
                        <label className="flex items-center mb-2">
                          <input
                            type="radio"
                            className="form-radio"
                            name="role"
                            value="sales"
                            checked={role == "sales"}
                            onChange={(e) => setRole(e.target.value)}
                          />
                          <span className="text-sm text-gray-600 dark:text-gray-400 ml-3">
                            Sales
                          </span>
                        </label>
                        <label className="flex items-center mb-2">
                          <input
                            type="radio"
                            className="form-radio"
                            name="role"
                            value="marketing"
                            checked={role == "marketing"}
                            onChange={(e) => setRole(e.target.value)}
                          />
                          <span className="text-sm text-gray-600 dark:text-gray-400 ml-3">
                            Marketing
                          </span>
                        </label>
                        <label className="flex items-center mb-2">
                          <input
                            type="radio"
                            className="form-radio"
                            name="role"
                            value="graphics_designer"
                            checked={role == "graphics_designer"}
                            onChange={(e) => setRole(e.target.value)}
                          />
                          <span className="text-sm text-gray-600 dark:text-gray-400 ml-3">
                            Graphics Designer
                          </span>
                        </label>
                        <label className="flex items-center mb-2">
                          <input
                            type="radio"
                            className="form-radio"
                            name="role"
                            value="other"
                            checked={role == "other"}
                            onChange={(e) => setRole(e.target.value)}
                          />
                          <span className="text-sm text-gray-600 dark:text-gray-400 ml-3">
                            Other
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-5">
                      <div className="w-full px-3">
                        <div className="flex justify-between items-center mb-1">
                          <label
                            className="block text-gray-800 dark:text-gray-300 text-sm font-medium"
                            htmlFor="details"
                          >
                            Tell us about yourself
                          </label>
                        </div>
                        <textarea
                          id="details"
                          rows="4"
                          className="form-textarea w-full"
                          placeholder=""
                          value={details}
                          onChange={(e) => setDetails(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mt-6">
                      <div className="w-full px-3">
                        <button className="btn text-white bg-blue-500 hover:bg-blue-400 w-full flex items-center">
                          <span>Submit</span>
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {isPromptSubmitted && (
                  <div className="">
                    <p
                      className={`text-center lg:text-left ${serverResponseColor} lg:absolute opacity-75 text-sm`}
                    >
                      {serverResponseText}
                    </p>
                  </div>
                )}
              </form>
            </div>
          </div>
        </section>
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default Jobs;
